<script>
    import Layout from "../layouts/main";
    import PageHeader from "@/components/admin/header/page-header";
    import axios from 'axios';
    import useVuelidate from "@vuelidate/core";
    import {
        required,
        helpers
    } from "@vuelidate/validators";

    export default {
        page: {
            title: "Coupons",
            meta: [
                {
                    name: "description",
                    content: 'description',
                },
            ],
        },

        data() {
            return {
                url: process.env.VUE_APP_URL,
                v$: useVuelidate(),
                isshow: false,
                isShowData: false,
                submitted: false,
                title: "Coupons",
                couponTitle: 'Add Coupon',
                searchValueTable: "",
                headers: [
                    { text: "Title", value: "title" },
                    { text: "Price", value: "amount" },
                    { text: "Type", value: "type" },
                    { text: "Action", value: "editData" }
                ],
                coupon: {
                    coupon_code: '',
                    discount_type: 'percentage',
                    discount_amount: ''
                },
                tableItems: [],
                tableData: [],
                // title: '',
                price: '',
                isCoupon: false,
                isCouponEdit: false

            }
        },
        components: {
            Layout,
            PageHeader
        },
        validations: {
            coupon: {
                coupon_code: {
                    required: helpers.withMessage("Coupon Code is required", required),
                },
                discount_amount: {
                    required: helpers.withMessage("Discount Amount is required", required),
                }
            }
        },
        mounted() {
            this.getCoupons()
        },
        activated() {
            this.getCoupons()
        },
        methods: {
            getCoupons() {
                axios.post(this.url + "api/getCoupon").then((res) => {
                    this.tableItems = res.data;
                });
            },
            addCoupon() {
                this.submitted = true;
                this.v$.$touch();
                if (this.v$.$invalid) {
                    return false;
                } else {
                    axios.post(this.url + "api/addCoupon", this.coupon).then(() => {
                        this.isCoupon = false;
                        this.$store.state.isAccepted = true;
                        this.$store.state.notificationData = 'Coupon Created Successfully'
                        this.getCoupons();
                        setTimeout(() => {
                            this.$store.state.isAccepted = false;
                            this.$store.state.notificationData = ''
                        }, 2500);
                    }).catch((error) => {
                        console.log(error.response.data.errors)
                        this.$store.state.isDeclined = true;
                        this.$store.state.notificationData = error.response.data.errors.coupon_code[0]
                        setTimeout(() => {
                            this.$store.state.isDeclined = false;
                            this.$store.state.notificationData = ''
                        }, 2500);
                    })
                }
            },
            editCoupon(id) {
                this.isCouponEdit = true;
                this.couponTitle = 'Update Coupon';
                this.isCoupon = true;
                axios.post(this.url + "api/editCoupon", { id: id }).then((res) => {
                    this.coupon = res.data;
                })
            },
            updateCoupon() {
                this.submitted = true;
                this.v$.$touch();
                if (this.v$.$invalid) {
                    return false;
                } else {
                    this.coupon.discount_type ='percentage';
                    axios.post(this.url + "api/updateCoupon", this.coupon).then(() => {
                        this.isCoupon = false;
                        this.$store.state.isAccepted = true;
                        this.$store.state.notificationData = 'Coupon Updated Successfully';
                        this.getCoupons();
                        setTimeout(() => {
                            this.$store.state.isAccepted = false;
                            this.$store.state.notificationData = ''
                        }, 2500);
                    }).catch((error) => {
                        console.log(error.response)
                        this.$store.state.isDeclined = true;
                        this.$store.state.notificationData = error.response.data.errors.coupon_code[0]
                        setTimeout(() => {
                            this.$store.state.isDeclined = false;
                            this.$store.state.notificationData = ''
                        }, 2500);
                    })
                }
            },
            resetFields() {
                this.submitted = false;
                this.coupon = {
                    coupon_code: '',
                    discount_type: '',
                    discount_amount: ''
                }
            },
        }
    };
</script>
<template>
    <layout>
        <div class="row mb-3 align-items-center">
            <div class="col">
                <PageHeader :title="title" />
            </div>
            <div class="col-auto">
                <a href="javascript:void(0)" class="btn btn-theme" @click="isCoupon = true, resetFields()">
                    <i class="bx bx-plus font-size-18 align-middle me-2"></i>Add Coupon</a>
            </div>
        </div>
        <div class="row">
            <div class="col-md-4" v-for="(couponData, index ) in tableItems" :key="index">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1 d-flex justify-content-between">
                                <div>
                                    <p class="text-muted fw-medium mb-0">Coupon Code</p>
                                    <h4 class="mb-0">{{ couponData.coupon_code }}</h4>
                                </div>
                                <div class="align-items-center d-flex justify-content-center pe-4">
                                    <h4 class="mb-0" v-if="couponData.discount_type == 'percentage'">
                                        {{ couponData.discount_amount }}%</h4>
                                    <h4 class="mb-0" v-else>${{ couponData.discount_amount }}</h4>
                                </div>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <a @click="editCoupon(couponData.id)" class="btn btn-sm btn-soft-info"><i
                                        class="mdi mdi-pencil-outline"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="col-md-4">
                <div class="card">
                    <div class="card-body">
                        <div class="d-flex">
                            <div class="flex-grow-1 d-flex justify-content-between">
                                <div>
                                    <p class="text-muted fw-medium mb-0">Coupon Code</p>
                                    <h4 class="mb-0">ADS32KHAD</h4>
                                </div>
                                <div class="align-items-center d-flex justify-content-center pe-4">
                                    <h4 class="mb-0">$10</h4>
                                </div>
                            </div>

                            <div class="flex-shrink-0 align-self-center">
                                <a @click="addCouponclick" class="btn btn-sm btn-soft-info"><i
                                        class="mdi mdi-pencil-outline"></i></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
        </div>
        <b-modal v-model="isCoupon" id="addCoupon" centered scrollable size="md" :title="couponTitle"
            title-class="font-18" hide-footer>
            <form>
                <div class="mainFormModal">
                    <div class="mb-3">
                        <label class="form-label">Coupon Code</label>
                        <div class="single-select2-cstm">
                            <input id="task-title-input" v-model="coupon.coupon_code" type="text" class="form-control"
                                placeholder="Type Coupon Code" :class="{
                    'is-invalid': submitted && v$.coupon.coupon_code.$error,
                }">
                            <div v-if="submitted && v$.coupon.coupon_code.$error" class="invalid-feedback">
                                <span v-if="v$.coupon.coupon_code.required.$message">
                                    {{ v$.coupon.coupon_code.required.$message }}
                                </span>
                            </div>
                        </div>
                    </div>
                    <!-- <div class="mb-3">
                        <label for="formrow-inputState" class="form-label">Select Type</label>
                        <select id="formrow-inputState" v-model="coupon.discount_type" class="form-select" :class="{
                    'is-invalid': submitted && v$.coupon.discount_type.$error,
                }">
                            <option value="fixed">Fixed Price</option>
                            <option value="percentage">Percentage (%)</option>
                        </select>
                        <div v-if="submitted && v$.coupon.discount_type.$error" class="invalid-feedback">
                            <span v-if="v$.coupon.discount_type.required.$message">
                                {{ v$.coupon.discount_type.required.$message }}
                            </span>
                        </div>
                    </div> -->
                    <div class="mb-3">
                        <label for="task-title-input">Discount %</label>
                        <input id="task-title-input" v-model="coupon.discount_amount" type="number" class="form-control"
                            placeholder="Type Price" :class="{
                    'is-invalid': submitted && v$.coupon.discount_amount.$error,
                }">
                        <div v-if="submitted && v$.coupon.discount_amount.$error" class="invalid-feedback">
                            <span v-if="v$.coupon.discount_amount.required.$message">
                                {{ v$.coupon.discount_amount.required.$message }}
                            </span>
                        </div>
                    </div>
                    <div class="d-flex justify-content-end border-top pt-3" v-if="isCouponEdit">
                        <button type="button" @click="updateCoupon" class="btn btn-theme">Submit</button>
                    </div>
                    <div class="d-flex justify-content-end border-top pt-3" v-else>
                        <button type="button" @click="addCoupon" class="btn btn-theme">Submit</button>
                    </div>
                </div>
            </form>
        </b-modal>
    </layout>
</template>
<style scoped></style>